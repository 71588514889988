import "./bootstrap";
import "../css/app.css";

// Import modules...
import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import VTooltip from "v-tooltip";
import Toast from "vue-toastification";
import dayjs from "dayjs";
import { connectToParent } from "penpal";
import "vue-toastification/dist/index.css";
import BaseMixin from "./Mixins/Base";
import VueAgile from "vue-agile";
import * as Sentry from "@sentry/vue";

createInertiaApp({
  progress: {
    color: "#564cd4",
  },
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    if (import.meta.env.VITE_APP_ENV === "production") {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [new Sentry.Replay()],
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        beforeSend: function (event) {
          const birdEatsBugSessionURL =
            window.birdeatsbug?.session?.link || window.birdeatsbugExtension?.session?.link;

          if (birdEatsBugSessionURL) {
            if (!event.contexts) event.contexts = {};
            event.contexts["Bird Eats Bug"] = {
              url: birdEatsBugSessionURL,
            };
          }
          return event;
        },
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    app
      .use(plugin)
      .use(VueAgile)
      .use(VTooltip)
      .mixin(BaseMixin)
      .use(Toast, {})
      .mixin({
        data() {
          return {
            isRunningInIframe: window.isRunningInIframe,
            parentConnection: window.isRunningInIframe
              ? connectToParent({
                  // Methods child is exposing to parent.
                  methods: {},
                })
              : null,
          };
        },
        methods: {
          route(name, params, absolute, config = this.$page.props.ziggy) {
            return route(name, params, absolute, config);
          },
          date(date, format = "DD/MM/YYYY") {
            return dayjs(date).format(format);
          },
          formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          },
          abbreviatedNumber(num) {
            return Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(num);
          },
          updateParentIframeOptions(options) {
            /**
             * If the app is loaded in an iframe, send a message to the parent window
             * to enable/disable the widget
             */
            if (this.parentConnection) {
              this.parentConnection.promise.then((parent) => {
                parent.updateOptions(options);
              });
            }
          },
        },
      })
      .mount(el);
  },
});
